<template>
  <div class="grid grid-2">
    <div class="card shadow w100">
      <h3 class="title">{{ $t("message.business_settings") }}</h3>
      <br />
      <div v-if="state.isErrors" class="errors">
        <label class="error-text" v-for="(err, k) in state.errorSet" :key="k">
          {{ err }}
        </label>
      </div>
      <form
        :class="state.isErrors ? 'form-has-errors' : ''"
        @submit.prevent="updateBusinessSettings"
      >
        <div class="grid">
          <div class="form-group">
            <input
              type="text"
              v-focus
              class="form-input"
              :placeholder="$t('message.company_name')"
              v-model="form.company_name"
            />
            <label>{{ $t("message.company_name") }}</label>
          </div>
        </div>
        <br />
        <div class="grid">
          <div class="form-group">
            <input
              type="email"
              class="form-input"
              v-focus
              v-model="form.business_email"
              :placeholder="$t('message.bussiness_email')"
            />
            <label>{{ $t("message.bussiness_email") }}</label>
          </div>
        </div>
        <br />
        <div class="grid grid-2 gap">
          <div class="form-group">
            <input
              type="text"
              v-focus
              v-model="form.phone"
              class="form-input"
              :placeholder="$t('message.phone')"
            />
            <label>{{ $t("message.phone") }}</label>
          </div>
          <div class="form-group">
            <input
              type="text"
              v-focus
              v-model="form.fax"
              class="form-input"
              :placeholder="$t('message.fax')"
            />
            <label>{{ $t("message.fax") }}</label>
          </div>
        </div>
        <br />
        <div class="grid grid-2 gap">
          <div class="form-group">
            <input
              type="text"
              v-model="form.rccm"
              v-focus
              class="form-input"
              :placeholder="$t('message.rccm')"
            />
            <label>{{ $t("message.rccm") }}</label>
          </div>
          <div class="form-group">
            <input
              type="text"
              v-model="form.niu"
              v-focus
              class="form-input"
              :placeholder="$t('message.niu')"
            />
            <label>{{ $t("message.niu") }}</label>
          </div>
        </div>
        <br />
        <div class="grid">
          <div class="form-group">
            <input
              type="text"
              v-focus
              v-model="form.address"
              class="form-input"
              :placeholder="$t('message.address')"
            />
            <label>{{ $t("message.address") }}</label>
          </div>
        </div>
        <br />
        <div class="grid grid-2 gap">
          <div class="form-group">
            <input
              type="text"
              v-focus
              v-model="form.city"
              class="form-input"
              :placeholder="$t('message.city')"
            />
            <label>{{ $t("message.city") }}</label>
          </div>
          <div class="form-group">
            <input
              type="text"
              v-focus
              v-model="form.postal_code"
              class="form-input"
              :placeholder="$t('message.postal_code')"
            />
            <label>{{ $t("message.postal_code") }}</label>
          </div>
        </div>
        <br />
        <button v-if="!form.loading" class="form-btn">
          {{ $t("message.update_settings") }}
        </button>
        <button class="form-btn disabled" disabled v-if="form.loading">
          Updaing...
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import { reactive, ref } from "@vue/reactivity";
import { useStore } from "vuex";
import { onMounted } from "@vue/runtime-core";
export default {
  setup() {
    const form = ref({
      loading: false,
      company_name: null,
      address: null,
    });
    const store = useStore();
    const state = reactive({
      isErrors: false,
      errorSet: [],
    });

    function updateBusinessSettings() {
      form.value.loading = true;
      state.isErrors = false;
      store
        .dispatch("account/updateBusinessSettings", form.value)
        .then((res) => {
          form.value.loading = false;
          if (res.status === "success") {
            store.commit("notification/SEND_NOTIFICATION", res);
          } else {
            state.isErrors = true;
            state.errorSet.push(res.message);
          }
        });
    }

    const onMountedFunc = () => {
      state.isErrors = false;
      state.errorSet = [];
      store.dispatch("account/fetchBusinessSettings").then((res) => {
        store.commit("component/SET_LOADER", false);
        if (res.status === "success") {
          form.value = res.data;
        }
      });
    };

    onMounted(() => onMountedFunc());

    return { form, updateBusinessSettings, state };
  },
};
</script>
